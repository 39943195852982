import React, { useRef, useState, useEffect, useCallback } from "react";
import "./player.css";
import AddPreview from "./addPreview";
import PreviewDashboard from "./previewDashboard";
import { deleteData, getData, patchData } from "../../../services";
import { useCookies } from "react-cookie";
import { useNavigate, useParams } from "react-router-dom";
import Add from "../../icons/add";
import Modal from "../../atoms/Modals/Modal";
import Cart from "../../icons/cart";
import "../videoplr/preview/style.scss";
import DeleteModal from "../../atoms/Modals/DeleteModal";
import ArrowRigth from "../../icons/arrowRight";
import CloseBtnIcom from "../../icons/CloseBtnIcom";
import ArrowLeft from "../../icons/arrowLeft";
import Copy from "../../icons/Copy";
import Tick from "../../icons/Tick";
import PageCard from "../../atoms/PageCard";
import { v4 as uuidv4 } from 'uuid';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import Plyr from 'plyr';
import 'plyr/dist/plyr.css';
import toast from "react-hot-toast";
import { confirmationStyles } from "../../../assets/styles/toast";


const VideoPlayer = () => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const playerRef = useRef(null);
  const [cookies] = useCookies(["t", "bskey", "auid"]);
  const { id } = useParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("Catalogue");

  const [searchValueCatalogue, setSearchValueCatalogue] = useState('');


  const [chooseCoordinates, setChooseCoordinates] = useState(false);
  const [hoverItem, setHoverItem] = useState({
    positionx: 0,
    positiony: 0,
  });
  const [DesctiptionData, setDesctiptionData] = useState([]);
  const [hightLightData, setHightLightData] = useState([]);
  const [videoData, setVideoData] = useState([]);
  const [CartData, setCartData] = useState([]);
  const [catalogueData, setCatalogueData] = useState([]);


  const [showBlinkers, setShowBlinkers] = useState(false);


  const [totalDescriptionItem, setTotalDescriptionItem] = useState();
  const [totalCartItem, setTotalCartItem] = useState();
  const [TotalHighlightItem, SetTotalHightlightItem] = useState();
  const [TotalCatalogueItem, SetTotalCatalogueItem] = useState();

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [currentPageCart, setCurrentPageCart] = useState(1);
  const [itemsPerPageCart, setItemsPerPageCart] = useState(10);

  const [currentPageHighlight, setCurrentPageHightlight] = useState(1);
  const [itemsPerPageHighlight, setItemsPerPageHighlight] = useState(10);

  const [totalCatalogueItem, setTotalCatalogueItem] = useState();
  const [currentPageCatalogue, setCurrentPageCatalogue] = useState(1);
  const [itemsPerPageCatalogue, setItemsPerPageCatalogue] = useState(10);

  const [videoDataPreviw, setVideoDataPreview] = useState([]);
  const [currentTime, setCurrentTime] = useState(0);

  const [searchValuedesc, setSearhValuedesc] = useState("");
  const [searchValuecart, setSearhValuecart] = useState("");
  const [searchValuehigh, setSearhValuehigh] = useState("");
  const [searchValueCollection, setSearchValueCollection] = useState("");
  const [showEmbededContain, setShowEmbededContain] = useState(false);
  const [collectionData, setCollectionData] = useState([]);

  const [isCustomPlayer, setIsCustomPlayer] = useState(true);
  const [isPlyrPlayer, setIsPlyrPlayer] = useState(false);



  useEffect(() => {
  if (videoRef.current) {
    if (isCustomPlayer) {
      // Custom player initialization
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
      videoRef.current.addEventListener('timeupdate', handleTimeUpdate);
      videoRef.current.src = videoData?.video_link || '';
      const currentTime = videoRef.current.currentTime;
      setCurrentTime(currentTime);
    } else if (isPlyrPlayer) {
      // Initialize Plyr
      if (!playerRef.current) {
        playerRef.current = new Plyr(videoRef.current, {
          controls: [
        'play-large', 'restart', 'rewind', 'play', 'fast-forward', 'progress', 'current-time', 'duration', 'mute', 'volume', 'captions', 'settings', 'pip', 'airplay', 'fullscreen'  
      ],
        });
        playerRef.current.source = {
          type: 'video',
          sources: [
            {
              src: videoData?.video_link,
              type: 'video/mp4',
            },
          ],
        };
        playerRef.current.on('timeupdate', () => {
          const currentTime = playerRef.current.currentTime;
          setCurrentTime(currentTime);
        });
      }
    } else {
      // Video.js player initialization
      if (!playerRef.current) {
        playerRef.current = videojs(videoRef.current, {
          controls: true,
          autoplay: false,
          preload: 'auto',
          sources: [
            {
              src: videoData?.video_link,
              type: 'video/mp4',
            },
          ],
        });
        playerRef.current.on('timeupdate', () => {
          const currentTime = playerRef.current.currentTime();
          setCurrentTime(currentTime);
        });
      }
    }
  }

  return () => {
    if (videoRef.current) {
      videoRef.current.removeEventListener('timeupdate', handleTimeUpdate);
    }
    if (playerRef.current) {
      playerRef.current.destroy ? playerRef.current.destroy() : playerRef.current.dispose();
      playerRef.current = null;
    }
  };
}, [isCustomPlayer, isPlyrPlayer, videoData]);




  const handleShopNowClick = () => {
    // console.log('Shop Now clicked. Current showBlinkers:', showBlinkers);
    if (videoRef.current.paused) {
      videoRef.current.play();
      setShowBlinkers(false);
    } else {
      videoRef.current.pause();
      setShowBlinkers(true);
    }
    // console.log('Updated showBlinkers:', !showBlinkers);
    // console.log("Shop Now clicked. showBlinkers:", showBlinkers);
  };

  useEffect(() => {
    // console.log("Initial videoData.purpose:", videoData.purpose);
    // console.log("Initial showBlinkers:", showBlinkers);
  }, [videoData]);




  const [isCopy, setIsCopy] = useState(false);
  const [linkCopy, setLinkCopy] = useState(false);

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1);
    setItemsPerPage(perPage);
  }, []);

  const handlePageChangeCart = useCallback((page) => {
    setCurrentPageCart(page);
  }, []);

  const handleItemsPerPageChangeCart = useCallback((perPage) => {
    setCurrentPageCart(1);
    setItemsPerPageCart(perPage);
  }, []);

  const handlePageChangeHighlight = useCallback((page) => {
    setCurrentPageHightlight(page);
  }, []);

  const handleItemsPerPageChangeHighlight = useCallback((perPage) => {
    setCurrentPageHightlight(1);
    setItemsPerPageHighlight(perPage);
  }, []);

  useEffect(() => {
    // console.log('Catalogue Data:', catalogueData);
  }, [catalogueData]);

  const [modalState, setModalState] = useState({
    isModalOpen: false,
    name: "",
    data: {},
    playerTime: "",
  });

  const [editModalState, setEditModalState] = useState({
    isModalOpen: false,
    name: "",
    id: "",
    data: {},
  });

  const [deleteModal, setDeleteModal] = useState({
    isModalOpen: false,
    title: "",
    data: {},
  });

  const [videoWidth, setVideoWidth] = useState();

  const [totalItemsCart, setTotalItemsCart] = useState();
  const [totalIndex, setTotalIndex] = useState(0);
  const [lastTime, setLastTime] = useState();
  const [showItemCart, setShowItemCart] = useState(true);

  useEffect(() => {
    const getWidth = videoRef.current.getBoundingClientRect().width;
    setVideoWidth(getWidth);
  }, []);

  const [isShortVideo, setIsShortVideo] = useState(false);

  useEffect(() => {
    getVideopreviewDataById();
  }, [DesctiptionData, CartData, hightLightData]);
  // Function to handle click events on the video player
  const handleClick = (event) => {
    console.log("event", event);
    const clickX = event.clientX;
    const clickY = event.clientY;
    const playerRect = videoRef.current.getBoundingClientRect();
    const playerX = playerRect.left;
    const playerY = playerRect.top;

    const playerClickX = clickX - playerX - 10;
    const playerClickY = clickY - playerY - 10;
    const videoPlayer = document.getElementById("video-player");
    const videoPlayerStyles = videoPlayer.getBoundingClientRect();

    setTimeout(() => {
      setModalState(() => ({
        isModalOpen: true,
        // name: `Add Product ${activeTab}`,
        name: `Add Product`,
        playerTime: videoRef.current.currentTime,
      }));
    }, 900);

    setHoverItem(() => ({
      positionx: (playerClickX * 100) / videoPlayerStyles.width,
      positiony: (playerClickY * 100) / videoPlayerStyles.height,
    }));
    if (!editModalState.isModalOpen) {
      if (document?.getElementById("ImageRemoveBtn")) {
        document.getElementById("ImageRemoveBtn").click();
      }
    }
  };

  const getCartDetail = async () => {
    try {
      const response = await getData({
        endpoint: "VideoProduct/getAllProductCards",
        params: {
          video_id: id,
          page: currentPageCart,
          page_limit: itemsPerPageCart,
          search_query: searchValuecart,
        },
        token: cookies.t,
      });
      setCartData(response?.data);
      setTotalCartItem(response?.total_record);
    } catch (error) {
      console.log(error);
    }
  };
  const getHighLightDetail = async () => {
    try {
      const response = await getData({
        endpoint: "VideoProduct/getAllHighlights",
        params: {
          video_id: id,
          page: currentPageHighlight,
          page_limit: itemsPerPageHighlight,
          search_query: searchValuehigh,
        },
        token: cookies.t,
      });
      setHightLightData(response?.data);
      SetTotalHightlightItem(response?.total_record);
    } catch (error) {
      console.log(error);
    }
  };



  const getCatalogueDetails = async () => {
  try {
    const response = await getData({
      endpoint: "VideoProduct/getVideoAnnotations",
      params: {
        video_id: id,
        page: 1,
        page_limit: 10,
      },
      token: cookies.t,
    });

    // console.log("Full Response:", response);

    let dataArray = [];
    if (Array.isArray(response?.data)) {
      dataArray = response.data;
    } else if (Array.isArray(response)) {
      dataArray = response;
    } else {
      console.error("Unexpected response structure:", response);
      return;
    }

    const formattedData = dataArray.flatMap((item, index) => {
      return item.shotAnnotations.flatMap((shotAnnotation) => {
        return shotAnnotation.objectAnnotations.map((objectAnnotation) => ({
          id: uuidv4(),
          description: objectAnnotation.description,
          confidence: objectAnnotation.confidence,
          createdBy: objectAnnotation.createdBy,
          createdDate: objectAnnotation.createdDate,
          endTime: objectAnnotation.endTimeOffset,
          entityId: objectAnnotation.entityId,
          languageCode: objectAnnotation.languageCode,
          modifiedBy: objectAnnotation.modifiedBy,
          modifiedDate: objectAnnotation.modifiedDate,
          startTime: objectAnnotation.startTimeOffset,
          videoId: objectAnnotation.videoId,
          x: objectAnnotation.center?.x * 100,
          y: objectAnnotation.center?.y * 100,
        }));
      });
    });

    // console.log("Formatted Data: ", formattedData);


    setCatalogueData(formattedData);
    SetTotalCatalogueItem(response.total_record || dataArray.length);

  } catch (error) {
    console.log(error);
  }
};









  const deleteHightLightDetail = async (hightlightID) => {
    try {
      const response = await deleteData({
        endpoint: "VideoProduct/deleteProductHighlight",
        params: {
          id: hightlightID,
        },
        token: cookies.t,
      });
      if (response) {
        getHighLightDetail();
        getVideopreviewDataById();
        setDeleteModal(() => ({
          isModalOpen: false,
          data: {},
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteCartDetail = async (cartID) => {
    try {
      const response = await deleteData({
        endpoint: "VideoProduct/deleteProductCard",
        params: {
          id: cartID,
        },
        token: cookies.t,
      });
      if (response) {
        getCartDetail();
        getVideopreviewDataById();
        setDeleteModal(() => ({
          isModalOpen: false,
          data: {},
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteDescriptionDetail = async (desId) => {
    try {
      const response = await deleteData({
        endpoint: "VideoProduct/deleteProductDescription",
        params: {
          id: desId,
        },
        token: cookies.t,
      });
      if (response) {
        getDesctiptionDetail();
        getVideopreviewDataById();
        setDeleteModal(() => ({
          isModalOpen: false,
          data: {},
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };
  const deleteCollectionDetails = async (collectionId) => {
    try {
      const res = await deleteData({
        endpoint: "VideoProduct/deleteProductCollection",
        params: {
          collection_id: collectionId,
        },
        token: cookies.t,
      });
      if (res) {
        getCollectionDetails();
        getVideoDataById();
        setDeleteModal(() => ({
          isModalOpen: false,
          data: {},
        }));
      }
    } catch (exp) {
      // console.log("exp", exp);
    }
  };

  const getDesctiptionDetail = async () => {
    try {
      const response = await getData({
        endpoint: "VideoProduct/getAllProductDescription",
        params: {
          video_id: id,
          page: currentPage,
          page_limit: itemsPerPage,
          search_query: searchValuedesc,
        },
        token: cookies.t,
      });
      setDesctiptionData(response?.data);
      setTotalDescriptionItem(response?.total_record);
    } catch (error) {
      console.log(error);
    }
  };

  const getVideoDataById = async () => {
    try {
      const response = await getData({
        endpoint: "Video/getVideoDetailsById",
        params: {
          video_id: id,
        },
        token: cookies.t,
      });
      // console.log('Fetched video data:', response);
      setVideoData(response);
    } catch (error) {
      console.log(error);
    }
  };

  const getCollectionDetails = async () => {
    try {
      const res = await getData({
        endpoint: "VideoProduct/getAllProductCollection",
        params: {
          video_id: id,
          page: 1,
          page_limit: 10,
        },
        token: cookies.t,
      });
      setCollectionData(res?.data);
      setChooseCoordinates(false);
      getVideopreviewDataById();
    } catch (exp) {
      console.log("Exp", exp);
    }
  };

  useEffect(() => {
    getCatalogueDetails();
  }, []);

    useEffect(() => {
    getCollectionDetails();
  }, []);

  useEffect(() => {
    getVideoDataById();
  }, []);

  useEffect(() => {
    getDesctiptionDetail();
  }, [itemsPerPage, currentPage, searchValuedesc]);

  useEffect(() => {
    getHighLightDetail();
  }, [itemsPerPageHighlight, currentPageHighlight, searchValuehigh]);

  useEffect(() => {
    getCartDetail();
  }, [itemsPerPageCart, currentPageCart, searchValuecart]);

  useEffect(() => {
    if (activeTab) {
      setSearhValuecart("");
    }
  }, [activeTab]);

  /*----------------Video Preview------------------ */

  const getVideopreviewDataById = async () => {
    try {
      const response = await getData({
        endpoint: "Video/getVideoPreview",
        params: {
          video_id: id,
        },
        token: cookies.t,
        secretkey: cookies.bskey,
      });
      setVideoDataPreview(response);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getVideopreviewDataById();
  }, []);

  const handleTimeUpdate = () => {
    setCurrentTime(videoRef.current.currentTime);
    // console.log("videoRef.current.currentTime", videoRef.current.currentTime);
    if (lastTime >= videoRef.current.currentTime) {
      setShowItemCart(true);
    }
  };

  // Function to check if the current time is within the range of any item
  const checkTimeInRange = ({ time, item }) => {
    // console.log(item?.start_time, item?.end_time);
    function calculateTotalSeconds(timeString) {
    // Expected input format "HH:mm:ss.S" -> "12:34:56.7"
      const parts = timeString.split(":");
      const secondsParts = parts[2].split(".");  // Split seconds and deciseconds

      const hours = parseInt(parts[0], 10);
      const minutes = parseInt(parts[1], 10);
      const seconds = parseInt(secondsParts[0], 10);
      const deciseconds = secondsParts.length > 1 ? parseInt(secondsParts[1], 10) : 0; // Get deciseconds if present, otherwise 0

      // Calculate total seconds as a float including deciseconds
      var totalSeconds = hours * 3600 + minutes * 60 + seconds + deciseconds / 10;
      
      return totalSeconds; // Return the total seconds including deciseconds
    }
    const startTime = calculateTotalSeconds(item.start_time);
    const endTime = calculateTotalSeconds(item.end_time);
    return time >= startTime && time <= endTime;
  };
  useEffect(() => {
    const videoElement = videoRef.current;

    // Add event listeners for time update
    videoElement.addEventListener("timeupdate", handleTimeUpdate);

    return () => {
      // Cleanup event listeners
      videoElement.removeEventListener("timeupdate", handleTimeUpdate);
    };
  }, []);
  const [videoStatus, setVideoStatus] = useState(false);

  const handleItemHover = ({ index, type, item }) => {
    // console.log("2-entered");
    // console.log("index  :", index, type);
    const videoStyles = videoRef.current.getBoundingClientRect();
    const hoveredItem = document.getElementById(
      type === "Des" ? `hover-container-${index}` : `hov-container-${index}`
    );
    const hoverStyles = hoveredItem.getBoundingClientRect();

    const itemLeft = (item.x_coordinate * videoStyles.width) / 100 - 10;
    const itemTop = (item.y_coordinate * videoStyles.height) / 100 - 10;

    // Optimize repositioning logic
    // console.log("v  :", hoverStyles);
    if (type === "Des" && itemLeft + 150 > videoStyles.width) {
      hoveredItem.style.left = `-150px`;
      hoveredItem.style.right = "auto";
    }
    // console.log("X-Axis :", hoverStyles.top);
    if (type === "Cart") {
      if (
        videoStyles.height - itemTop + 200 > videoStyles.height ||
        hoverStyles.top < 0
      ) {
        hoveredItem.style.top = "40px";
        hoveredItem.style.left = "auto";
        hoveredItem.style.bottom = "auto";
      } else {
        hoveredItem.style.bottom = "40px";
        hoveredItem.style.left = "auto";
        hoveredItem.style.top = "auto";
      }
    }
    // else {
    //     hoveredItem.style.bottom = "40px";
    //     hoveredItem.style.left = "auto";
    //     hoveredItem.style.top = "auto";
    //   }
    // }
    let isTopNegative = false;

    const handleScroll = () => {
      const hoverStyles = hoveredItem.getBoundingClientRect();

      if (!isTopNegative && hoverStyles.top < 0 && type === "Cart") {
        // console.log("1-entered");
        hoveredItem.style.top = "40px";
        hoveredItem.style.left = "auto";
        hoveredItem.style.bottom = "auto";
        isTopNegative = true; // Set the flag to true to indicate that the condition has been met
      } else if (isTopNegative && hoverStyles.top >= 0 && type === "Cart") {
        // If top is no longer negative, reset the styles and the flag
        // console.log("2-entered");
        hoveredItem.style.bottom = "40px";
        hoveredItem.style.left = "auto";
        hoveredItem.style.top = "auto";
        isTopNegative = false;
      }
    };
    if (videoRef?.current?.paused) {
      setVideoStatus(true);
    } else {
      setVideoStatus(false);
    }

    videoRef.current.pause();

    window.addEventListener("scroll", handleScroll);

    // Cleanup event listener on component unmount or when handleItemHover is called again
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  };

  const handleItemHoverRemove = () => {
    // if (videoRef && videoRef.current) {
    //   if (!videoStatus && videoRef.current.readyState >= 10) {
    //     videoRef.current.pause();
    //   } else if (videoStatus && videoRef.current.readyState >= 10) {
    //     videoRef.current.play();
    //   }
    // }
    if (currentTime !== 0) videoRef.current.play();
  };

  const handleDeleteModal = (DeleteType) => {
    if (DeleteType === "Description") {
      deleteDescriptionDetail(deleteModal?.data);
    } else if (DeleteType === "Cart") {
      deleteCartDetail(deleteModal?.data);
    } else if (DeleteType === "Collection") {
      deleteCollectionDetails(deleteModal?.data);
    } else {
      deleteHightLightDetail(deleteModal?.data);
    }
  };

  const handlePubishButton = async () => {
    try {
      const res = await patchData({
        endpoint: "Video/publishVideo",
        params: {
          video_id: id,
          is_published: true,
        },
        token: cookies.t,
      });
      if (res) {
        setShowEmbededContain(true);
        getVideopreviewDataById();
        toast.success("Video Was Published Successfully", {
          duration: 2000,
          style: confirmationStyles,
        });
      }
    } catch (exp) {
      console.log("exp", exp);
    }
  };
  const handleCopyUrl = () => {
    var text = document?.getElementById("EmbededIframe")?.innerHTML;
    // Decode HTML entities
    const tempElement = document.createElement("div");
    tempElement.innerHTML = text;
    text = tempElement.textContent;

    navigator?.clipboard?.writeText(text);
    setIsCopy(true);
    setLinkCopy(false);
    toast.success("Copied to Clipboard.", {
      duration: 2000,
      style: confirmationStyles,
    });
  };
  const handleCopyUrlLink = () => {
    var text = document?.getElementById("EmbededIframe1")?.innerHTML;
    // Decode HTML entities
    const tempElement = document.createElement("div");
    tempElement.innerHTML = text;
    text = tempElement.textContent;

    navigator?.clipboard?.writeText(text);
    setLinkCopy(true);
    setIsCopy(false);
    toast.success("Copied to Clipboard.", {
      duration: 2000,
      style: confirmationStyles,
    });
  };

  useEffect(() => {
    const updateVideoStyles = () => {
      const videoElement = videoRef?.current;
      if (!videoElement) return;

      const videoStyles = videoElement.getBoundingClientRect();
      if (videoStyles) {
        if (videoStyles.width < videoStyles.height) {
          setIsShortVideo(true);
        } else {
          setIsShortVideo(false);
        }
      }
    };

    // Check video styles every 500ms
    const intervalId = setInterval(updateVideoStyles, 500);

    // Clear the interval on component unmount
    return () => clearInterval(intervalId);
  }, [videoData]);



  return (
    <>
      <div className="video-add-conatiner-main">
        <div>
          <div style={{ position: "relative" }}>
            {isCustomPlayer ? (
              <div key="custom-player">
                <video
                  id="video-player"
                  className={isShortVideo ? 'short-video-container' : 'video-container'}
                  ref={videoRef}
                  controls
                  controlslist="nodownload"
                  disablePictureInPicture
                ></video>
              </div>
            ) : isPlyrPlayer ? (
              <div key="plyr-player">
                <video
                  id="video-player"
                  ref={videoRef}
                  className={`plyr-container ${isShortVideo ? 'short-video-container' : 'video-container'}`}
                  controls
                ></video>
              </div>
            ) : (
              <div key="video-js-player">
                <video
                  id="video-player"
                  ref={videoRef}
                  className={`video-js vjs-default-skin vjs-big-play-centered ${isShortVideo ? 'short-video-container' :          'video-container'}`}
                  controls
                ></video>
              </div>
            )}
            <canvas ref={canvasRef} style={{ display: "none" }} />


            {/* "Shop Now" Button */}
            {videoData.purpose === "Entertainment" && (
              <div className="shop-now-container" style={{ position: "absolute", bottom: "70px", right: "20px" }}>
                <button
                  className="shop-now-button"
                  onClick={handleShopNowClick}
                >
                  SHOP
                </button>
              </div>

            )
            }



           {(showBlinkers || videoData.purpose === "Advertisement") && videoDataPreviw?.product_description && (
              <>
                {videoDataPreviw?.product_description?.map(
                  (item, index) =>
                    checkTimeInRange({
                      time: currentTime,
                      item: item,
                    }) && (
                      <div
                        key={index}
                        className="main-item-wrap"
                        style={{
                          position: "absolute",
                          top: `calc(${item.y_coordinate}% - 10px)`,
                          left: `calc(${item.x_coordinate}% - 10px)`,
                        }}
                        onMouseEnter={() =>
                          handleItemHover({ index, type: "Des", item })
                        }
                        onMouseLeave={() =>
                          handleItemHoverRemove({ index, type: "Des" })
                        }
                      >
                        <div className="item">
                          <div className="item-dot-container"></div>
                        </div>
                        {item?.link ? (
                          <a
                            href={item?.link}
                            target="_blank"
                            style={{ textDecoration: "none" }}
                          >
                            <div
                              className="item-content-display extra-desc-styles"
                              style={{ left: "40px" }}
                              id={`hover-container-${index}`}
                            >
                              {item?.photo && (
                                <img
                                  src={item?.photo}
                                  className="item-description-image"
                                />
                              )}
                              <div className="item-content-display-hover">
                                <h3>{item?.name}</h3>
                                <p>{item?.description}</p>
                              </div>
                            </div>
                          </a>
                        ) : (
                          <div
                            className="item-content-display extra-desc-styles"
                            style={{ left: "40px" }}
                            id={`hover-container-${index}`}
                          >
                            {item?.photo && (
                              <img
                                src={item?.photo}
                                className="item-description-image"
                              />
                            )}
                            <div className="item-content-display-hover">
                              <h3>{item?.name}</h3>
                              <p>{item?.description}</p>
                            </div>
                          </div>
                        )}
                      </div>
                    )
                )}
              </>
            )}
            {(showBlinkers || videoData.purpose === "Advertisement") && videoDataPreviw?.product_card && (
              <>
                {videoDataPreviw?.product_card?.map(
                  (item, index) =>
                    checkTimeInRange({
                      time: currentTime,
                      item: item,
                    }) && (
                      <div
                        key={index}
                        className="main-item-wrap"
                        style={{
                          position: "absolute",
                          top: `calc(${item.y_coordinate}% - 10px)`,
                          left: `calc(${item.x_coordinate}% - 10px)`,
                        }}
                        onMouseEnter={() =>
                          handleItemHover({ index, type: "Cart", item })
                        }
                        onMouseLeave={() =>
                          handleItemHoverRemove({ index, type: "Cart" })
                        }
                      >
                        <div className="item shopping-item-icon-container">
                          <div className="item-shopping-icon ">
                            <Cart />
                          </div>
                        </div>
                        <div
                          className="item-content-display extra-card-styles"
                          style={{ bottom: "40px" }}
                          id={`hov-container-${index}`}
                        >
                          {item?.photo && <img src={item?.photo} />}
                          <div className="item-content-display-hover">
                            <h3>{item?.name}</h3>
                            <p>{item?.description}</p>
                            <div className="d-flex gap-1">
                              {item?.currency ? (
                                <h4>{`${item?.currency}`}</h4>
                              ) : (
                                ""
                              )}
                              {item?.price ? <h4>{`${item?.price}`}</h4> : ""}
                            </div>
                            <a href={item?.link} target="_blank">
                              <button
                                className="btn cart-btn-continer"
                                // onClick={() => item?.link}
                              >
                                {item?.product_cta
                                  ? item?.product_cta
                                  : "Add to Cart"}
                              </button>
                            </a>
                          </div>
                        </div>
                      </div>
                    )
                )}
              </>
            )}
            {(showBlinkers || videoData.purpose === "Advertisement") && videoDataPreviw?.product_highlight &&
              videoDataPreviw?.product_highlight?.length > 0 && (
                <>
                  {videoDataPreviw?.product_highlight?.map(
                    (item) =>
                      checkTimeInRange({
                        time: currentTime,
                        item: item,
                      }) && (
                        <div
                          className={
                            (item.x_coordinate * videoWidth) / 100 >
                            videoWidth / 2
                              ? "item-highlight"
                              : "item-highlight-left"
                          }
                          style={{
                            position: "absolute",
                            top: `${item.y_coordinate}%`,
                            left: `${item.x_coordinate}%`,
                          }}
                          // onMouseEnter={handleItemHover}
                          // onMouseLeave={handleItemHoverRemove}
                        >
                          {item?.link ? (
                            <a
                              href={item?.link}
                              target="_blank"
                              style={{ textDecoration: "none" }}
                            >
                              <div className="item-highlight-container">
                                {item?.name}
                              </div>
                            </a>
                          ) : (
                            <div className="item-highlight-container">
                              {item?.name}
                            </div>
                          )}
                          {/* <div className="line"></div> */}
                        </div>
                      )
                  )}
                </>
              )}


            {(showBlinkers || videoData.purpose === "Advertisement") && videoDataPreviw?.product_collection && (
              <>
                {videoDataPreviw?.product_collection?.map(
                  (item, index) =>
                    checkTimeInRange({
                      time: currentTime,
                      item: item,
                    }) && (
                      <>
                        {/* {console.log(index, item)} */}
                        {/* <div
                          className="Total-shop-collection"
                          onClick={() => {
                            setTotalItemsCart(item?.id);
                            setTotalIndex(0);
                          }}
                          style={{
                            top: `${item?.y_coordinate}%`,
                            left: `${item?.x_coordinate}%`,
                          }}
                        >
                          Shop the collection
                        </div> */}
                        {/* {item?.id === totalItemsCart && ( */}
                        {showItemCart && (
                          <>
                            {console.log(
                              "final ====> ",
                              item?.id === totalItemsCart
                            )}
                            <div
                              key={index}
                              className="total-collection-wrap-main"
                            >
                              <div className="total-collection-outer-wrap">
                                {item?.collection_data?.length > 3 && (
                                  <div
                                    style={{
                                      color: "white",
                                      cursor: "pointer",
                                      marginRight: "15px",
                                    }}
                                    onClick={() => {
                                      if (totalIndex > 0) {
                                        setTotalIndex(totalIndex - 1);
                                      }
                                    }}
                                  >
                                    <ArrowLeft
                                      className={
                                        totalIndex > 0
                                          ? "right-collection-btn"
                                          : ""
                                      }
                                    />
                                  </div>
                                )}
                                <div className="total-collection-inner-wrap">
                                  <div
                                    className="total_collection_wrap"
                                    style={{
                                      transform: `translate3d(calc(${totalIndex} * -165px), 0, 0)`,
                                    }}
                                  >
                                    {console.log("item  :", item)}
                                    {item?.collection_data?.map(
                                      (item, index) => (
                                        <div
                                          className="total-collection-container item-content-display extra-card-styles"
                                          key={index}
                                        >
                                          {item?.photo && (
                                            <img src={item?.photo} />
                                          )}
                                          <div className="item-content-display-hover">
                                            <h3>{item?.name}</h3>
                                            <p>{item?.description}</p>
                                            <div className="d-flex gap-1">
                                              {item?.currency ? (
                                                <h4>{`${item?.currency}`}</h4>
                                              ) : (
                                                ""
                                              )}
                                              {item?.price ? (
                                                <h4>{`${item?.price}`}</h4>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                            <a
                                              href={item?.link}
                                              target="_blank"
                                            >
                                              <button
                                                className="btn cart-btn-continer"
                                                onClick={() => {
                                                  /* Define what happens on click */
                                                }}
                                              >
                                                {videoDataPreviw
                                                  ?.product_collection[0]
                                                  ?.product_cta
                                                  ? videoDataPreviw
                                                      ?.product_collection[0]
                                                      ?.product_cta
                                                  : "Add to Cart"}
                                              </button>
                                            </a>
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>
                                {item?.collection_data?.length > 3 && (
                                  <div
                                    style={{
                                      color: "white",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      if (
                                        item?.collection_data?.length - 3 >=
                                        totalIndex + 1
                                      )
                                        setTotalIndex(totalIndex + 1);
                                    }}
                                  >
                                    <ArrowRigth
                                      className={
                                        item?.collection_data?.length - 3 >=
                                        totalIndex + 1
                                          ? "right-collection-btn"
                                          : ""
                                      }
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                            <div
                              className="total-cross-btn-collection"
                              onClick={() => {
                                setTotalItemsCart(null);
                                setTotalIndex(0);
                                // setLastTime(convertToSeconds(item.end_time));
                                setLastTime(currentTime);
                                setShowItemCart(false);
                              }}
                            >
                              <CloseBtnIcom />
                            </div>
                          </>
                        )}
                      </>
                    )
                )}
              </>
            )}

            <div
              className="video-add-icon-wrap Add_tooltip_btn"
              onClick={() => {
                setChooseCoordinates(true);
                videoRef.current.pause();
              }}
            >
              <div className="content-add-icon"></div>
              <Add className="video-add-icon" />
            </div>
            {chooseCoordinates && (
              <div
                style={{
                  position: "absolute",
                  width: "58vw",
                  height: "74.7vh",
                  top: "0",
                }}
                onClick={handleClick}
              ></div>
            )}
            {chooseCoordinates && (
              <div
                className="item"
                style={{
                  position: "absolute",
                  top: `${hoverItem.positiony}%`,
                  left: `${hoverItem.positionx}%`,
                  backgroundColor: "red",
                  width: "20px",
                  height: "20px",
                }}
                onClick={handleClick}
              ></div>
            )}
          </div>
        </div>
        {videoDataPreviw?.is_published && (
          <>
            <PageCard styles={{ height: "fit-content" }}>
              <div class="container">
                <div class="row ">
                  <div class="col first_child_row">Video Name</div>
                  <div class="col second_child_row">
                    {videoDataPreviw?.video_name}
                  </div>
                </div>
                <div class="row">
                  <div class="col  first_child_row">Duration</div>
                  <div class="col second_child_row">
                    {videoRef?.current?.duration + "s"}
                  </div>
                </div>
                <div className="row flex-column mb-2">
                  <div
                    className="d-flex w-100 justify-content-between"
                    style={{ paddingRight: "0" }}
                  >
                    <div
                      class="col first_child_row"
                      style={{ marginBottom: "5px" }}
                    >
                      Video URL
                    </div>
                    {linkCopy && (
                      <div style={{ fontWeight: "700" }}>
                        <Tick />
                      </div>
                    )}
                    {!linkCopy && (
                      <div
                        className="embeded-wrap"
                        onClick={() => {
                          handleCopyUrlLink();
                        }}
                      >
                        <Copy />
                      </div>
                    )}
                  </div>
                  <div
                    class="col second_child_row w-100"
                    id="EmbededIframe1"
                  >{`${process.env.REACT_APP_VIDEO_BASE_URL + "/" + id}`}</div>
                </div>
                <div class="row" className="embed-video-wrap">
                  <div className="d-flex w-100 justify-content-between">
                    <div
                      class="col first_child_row"
                      style={{ marginBottom: "5px" }}
                    >
                      Embed Video
                    </div>
                    {isCopy && (
                      <div style={{ fontWeight: "700" }}>
                        <Tick />
                      </div>
                    )}
                    {!isCopy && (
                      <div
                        className="embeded-wrap"
                        onClick={() => {
                          handleCopyUrl();
                        }}
                      >
                        <Copy />
                      </div>
                    )}
                  </div>

                  <div
                    class="col embeded-wrap second_child_row w-100"
                    id="EmbededIframe"
                  >
                    {'<iframe width="645" height="400px" src="' +
                      `${
                        process.env.REACT_APP_VIDEO_BASE_URL + "/" + id
                      }/embed` +
                      '" title="video-vogue" />'}
                  </div>
                </div>
              </div>

              
              <div className="player-switch-buttons">
                <button
                  className={`player-button ${isCustomPlayer ? 'active' : ''}`}
                  onClick={() => {
                    setIsCustomPlayer(true);
                    setIsPlyrPlayer(false);
                  }}
                  disabled={isCustomPlayer}
                >
                  Proprietary Player
                </button>
                
                <button
                  className={`player-button ${!isCustomPlayer && !isPlyrPlayer ? 'active' : ''}`}
                  onClick={() => {
                    setIsCustomPlayer(false);
                    setIsPlyrPlayer(false);
                  }}
                  disabled={!isCustomPlayer && !isPlyrPlayer}
                >
                  Video.js Player
                </button>
                
                <button
                  className={`player-button ${isPlyrPlayer ? 'active' : ''}`}
                  onClick={() => {
                    setIsCustomPlayer(false);
                    setIsPlyrPlayer(true);
                  }}
                  disabled={isPlyrPlayer}
                >
                  Plyr Player
                </button>
              </div>


            </PageCard>
          </>
        )}
        <Modal
          isActive={modalState.isModalOpen}
          onClose={() => {
            setModalState(() => ({
              isModalOpen: false,
              data: {},
              name: "",
            }));
            setEditModalState(() => ({
              isModalOpen: false,
              data: {},
              name: "",
            }));

            setChooseCoordinates(false);
            setHoverItem(() => ({
              positionx: 0,
              positiony: 0,
            }));
          }}
          title={
            editModalState?.isModalOpen ? editModalState?.name : modalState.name
          }
        >
          <AddPreview
            hoverItem={hoverItem}
            setHoverItem={setHoverItem}
            chooseCoordinates={chooseCoordinates}
            setChooseCoordinates={setChooseCoordinates}
            getDesctiptionDetail={getDesctiptionDetail}
            getHighLightDetail={getHighLightDetail}
            getCatalogueDetails={getCatalogueDetails}
            getCartDetail={getCartDetail}
            getCollectionDetails={getCollectionDetails}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            playerTime={modalState.playerTime}
            videoLink={videoData?.video_link}
            setModalState={setModalState}
            videoEditTable={editModalState.data}
            setEditModalState={setEditModalState}
            CartData={CartData}
            collectionData={collectionData}
            isDisabled={editModalState.isDisabled}
          />
        </Modal>
      </div>
      <PreviewDashboard
        CartData={CartData}
        videoRef={videoRef}
        setHoverItem={setHoverItem}
        hightLightData={hightLightData}
        DesctiptionData={DesctiptionData}
        collectionData={collectionData}
        catalogueData={catalogueData}
        searchValuedesc={searchValuedesc}
        setSearhValuedesc={setSearhValuedesc}
        searchValuecart={searchValuecart}
        setSearhValuecart={setSearhValuecart}
        searchValuehigh={searchValuehigh}
        setSearhValuehigh={setSearhValuehigh}
        searchValueCatalogue={searchValueCatalogue}
        setSearchValueCatalogue={setSearchValueCatalogue}
        getVideopreviewDataById={getVideopreviewDataById}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        setModalState={setModalState}
        setEditModalState={setEditModalState}
        setChooseCoordinates={setChooseCoordinates}
        handleItemsPerPageChange={handleItemsPerPageChange}
        handlePageChange={handlePageChange}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        totalDescriptionItem={totalDescriptionItem}
        totalCartItem={totalCartItem}
        TotalHighlightItem={TotalHighlightItem}
        TotalCatalogueItem={TotalCatalogueItem}
        itemsPerPageHighlight={itemsPerPageHighlight}
        currentPageHighlight={currentPageHighlight}
        itemsPerPageCart={itemsPerPageCart}
        currentPageCart={currentPageCart}
        handleItemsPerPageChangeHighlight={handleItemsPerPageChangeHighlight}
        handlePageChangeHighlight={handlePageChangeHighlight}
        handleItemsPerPageChangeCart={handleItemsPerPageChangeCart}
        handlePageChangeCart={handlePageChangeCart}
        // handleItemsPerPageChangeCatalogue={handleItemsPerPageChangeCatalogue}
        // handlePageChangeCatalogue={handlePageChangeCatalogue} 
        totalCatalogueItem={totalCatalogueItem}
        currentPageCatalogue={currentPageCatalogue}
        itemsPerPageCatalogue={itemsPerPageCatalogue}

        setDesctiptionData={setDesctiptionData}
        setCartData={setCartData}
        setHightLightData={setHightLightData}

        setDeleteModal={setDeleteModal}
        handlePubishButton={handlePubishButton}
        videoDataPreviw={videoDataPreviw}
        videoData={videoData}
      />
      <DeleteModal
        isActive={deleteModal.isModalOpen}
        onClick={() => {
          handleDeleteModal(deleteModal.title);
        }}
        onClose={() => {
          setDeleteModal(() => ({
            isModalOpen: false,
            data: {},
          }));
        }}
      />
      <footer className="video-player-wrap">
        <button
          style={{ borderRadius: "8px" }}
          className="btn btn-outline-dark mt-0 me-2"
          onClick={() => navigate(-1)}
        >
          Back
        </button>
      </footer>
    </>
  );
};

export default VideoPlayer;
